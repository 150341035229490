AOS.init();

$(function() {
    var header = $("#header-main");
    $(window).scroll(function() {
        var scroll = $(window).scrollTop();

        if (scroll >= 1) {
            header.addClass("sticky");
        } else {
            header.removeClass("sticky");
        }
    });
});

$('.product-slider-start').slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: true,
    arrows: false,
    easing:'linear',
    autoplaySpeed: 0,
    speed:3000,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 640,
            settings: {
                slidesToShow: 1,
            }
        }
    ]
});

$('.image-slider-fullwidth').slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    pauseOnHover: true,
    easing:'linear',
    centerMode: true,
    variableWidth: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 640,
            settings: {
                slidesToShow: 1,
                arrows: false
            }
        }
    ]
});

// Slider Product Detail

$('.prod-detail-top-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: '.prod-detail-top-slider-nav'
});

$('.prod-detail-top-slider-nav').slick({
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    asNavFor: '.prod-detail-top-slider',
    focusOnSelect: true,
    responsive: [
        {
            breakpoint: 639,
            settings: {
                slidesToShow: 2
            }
        }
    ]
});

    $("a[href^='#']").click(function(e) {
        var a = $($(this).attr("href")).offset().top;
        
        if ($(window).width() > 1100) {
            $("body, html").animate({
                scrollTop: a - 140
                }, 750,
                'linear' 
            ); 
         }
         else if ($(window).width() < 800) {
            $("body, html").animate({
                scrollTop: a - 180
                }, 750,
                'linear' 
            ); 
         }
         else {
            $("body, html").animate({
                scrollTop: a - 220
                }, 750,
                'linear' 
            );  
         }
    });

// OffCanvas
$(".off-canvas-toggle").click(function () {
    $(".hamburger").toggleClass("is-active");
    $(".nav-wrapper").toggleClass("active");
    $("body").toggleClass("no-scroll");
});

$(".nav-wrapper > ul > li > a").click(function () {
    $(".nav-wrapper").removeClass("active");
    $(".hamburger").removeClass("is-active");
    $("body").removeClass("no-scroll");
});

// Press "ESC"

$(document).keyup(function (e) {
    if (e.keyCode == 27) {
        $(".hamburger").removeClass("is-active");
        $(".nav-wrapper").removeClass("active");
        $("body").removeClass("no-scroll");
    }
});

// Lightbox

$('#lightbox').slickLightbox({
    caption: 'caption',
    useHistoryApi: 'true',
    navigateByKeyboard: 'true'
});